import { DestroyRef, inject, Injectable } from '@angular/core';
import { BehaviorSubject, fromEvent, tap } from 'rxjs';
import { UserActivityModel } from '../../carte/pastille/user-activity.model';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MessageService } from './message.service';

@Injectable({
  providedIn: 'root',
})
export class UserActivityService {
  private activityTimeout: any;
  private recentlyActiveTimeout: any;

  private readonly INACTIVITY_TIME = 300000; // 5 minutes (inactif)
  private readonly RECENTLY_ACTIVE_TIME = 60000; // 1 minute (récemment actif)

  private userActivity$ = new BehaviorSubject<UserActivityModel>(
    UserActivityModel.ACTIVE,
  );

  private destroyRef = inject(DestroyRef);

  constructor(private messageService: MessageService) {}

  public startMonitoringUserActivity() {
    const events = ['mousemove', 'keydown', 'scroll', 'click'];

    events.forEach((event) => {
      fromEvent(window, event)
        .pipe(
          takeUntilDestroyed(this.destroyRef),
          tap(() => this.resetTimer()),
        )
        .subscribe();
    });

    fromEvent(document, 'visibilitychange')
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        tap(() => {
          if (document.hidden) {
            this.setRecentlyActive();
          } else {
            this.resetTimer();
          }
        }),
      )
      .subscribe();

    this.resetTimer();
  }

  private resetTimer() {
    clearTimeout(this.activityTimeout);
    clearTimeout(this.recentlyActiveTimeout);

    this.userActivity$.next(UserActivityModel.ACTIVE);
    this.messageService.sendUserState(UserActivityModel.ACTIVE);

    this.recentlyActiveTimeout = setTimeout(() => {
      this.setRecentlyActive();
    }, this.RECENTLY_ACTIVE_TIME);

    this.activityTimeout = setTimeout(() => {
      this.userActivity$.next(UserActivityModel.INACTIVE);
      this.messageService.sendUserState(UserActivityModel.INACTIVE);
    }, this.INACTIVITY_TIME);
  }

  private setRecentlyActive() {
    clearTimeout(this.recentlyActiveTimeout);
    this.userActivity$.next(UserActivityModel.RECENTLY);
    this.messageService.sendUserState(UserActivityModel.RECENTLY);
  }

  public getUserActivity() {
    return this.userActivity$.asObservable();
  }
}
